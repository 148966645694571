import { Box, Checkbox, FormLabel, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';

const availableAccounts = [ // remove threads from now
  'LordOfIndiana@Twitter',
  //'LordOfSavings@Twitter',
  //'LordOfRestocks@Twitter',
  'LordOfDiscounts@Twitter',
  //'LordOfSavings@Threads',
  //'LordOfRestocks@Threads',
  // 'LordOfDiscounts@Threads',
  'team-chat@Discord',
  'community-deals@Discord',
  'general-deals@Discord',
  'freebies@Discord',
  'Price-Errors@Discord',
  'Discounts@Discord',
  'Tech-Deals@Discord',
  'In-Store Deals@Discord',
  'Homegood-Deals@Discord',
  'Fashion-Deals@Discord',
  'Food-Deals@Discord',
  'Parenting-Deals@Discord',
  'Location Based Deals@Discord',
  'Black Friday@Discord'
  //'OTC-Coupons@Discord',
  //'In-Demand Items@Discord',
];

export function DealEditorSocalAccountsSelector({
  dealDetails,
  setDealDetails,
}) {
  // React.useEffect(() => {
  //   console.log(`checked accounts: ${dealDetails.socials_accounts}`);
  // }, [dealDetails.socials_accounts]);

  const groupedAccounts = availableAccounts.reduce((acc, account) => {
    const [name, platform] = account.split('@');
    if (!acc[name]) acc[name] = {};
    acc[name][platform.toLowerCase()] = true;
    return acc;
  }, {});

  const rows = Object.keys(groupedAccounts).map((name, index) => {
    const account = groupedAccounts[name];
    return {
      id: `account-${index}`,
      name,
      twitter: account.twitter || false,
      threads: account.threads || false, // disable this for now
      discord: account.discord || false,
    };
  });

  const twitterThreadsRows = rows.filter((row) => row.twitter || row.threads);
  const discordRows = rows.filter((row) => row.discord);

  const columns = [
    {
      field: 'name',
      headerName: 'Accounts',
      width: 195,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={isAllChecked(params.row)}
            onChange={handleAllCheckboxChange(params.row)}
          />
          <Typography>{params.row.name}</Typography>
        </Box>
      ),
    },
    {
      field: 'twitter',
      headerName: 'Twitter',
      width: 70,
      sortable: false,
      renderCell: (params) =>
        params.row.twitter && (
          <Checkbox
            checked={isChecked(params.row.name, 'Twitter')}
            onChange={handleCheckboxChange(params.row.name, 'Twitter')}
          />
        ),
    },
    {
      field: 'threads',
      headerName: 'Threads',
      width: 75,
      sortable: false,
      renderCell: (params) =>
        params.row.threads && (
          <Checkbox
            checked={isChecked(params.row.name, 'Threads')}
            onChange={handleCheckboxChange(params.row.name, 'Threads')}
            disabled={true}
          />
        ),
    },
  ];

  const discordColumns = [
    {
      field: 'name',
      headerName: 'Discord Channels',
      width: 190,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={isChecked(params.row.name, 'Discord')}
            onChange={handleCheckboxChange(params.row.name, 'Discord')}
          />
          <Typography>{params.row.name}</Typography>
        </Box>
      ),
    },
  ];

  const handleAllCheckboxChange = (row) => (event) => {
    const platforms = ['Twitter', 'Threads', 'Discord']; // disable the thread
    const selectedAccounts = platforms
      .filter((platform) => row[platform.toLowerCase()])
      .map((platform) => `${row.name}@${platform}`);

    setDealDetails((prevDetails) => {
      const socialsAccounts = prevDetails.socials_accounts || [];
      const newAccounts = event.target.checked
        ? [...new Set([...socialsAccounts, ...selectedAccounts])]
        : socialsAccounts.filter((acc) => !selectedAccounts.includes(acc));
      return { ...prevDetails, socials_accounts: newAccounts };
    });
  };

  const isAllChecked = (row) => {
    const platforms = ['Twitter', 'Threads', 'Discord']; // diable the thread
    return platforms
      .filter((platform) => row[platform.toLowerCase()])
      .every((platform) => isChecked(row.name, platform));
  };

  const handleCheckboxChange = (name, platform) => (event) => {
    const selectedAccount = `${name}@${platform}`;
    setDealDetails((prevDetails) => {
      const socialsAccounts = prevDetails.socials_accounts || []; // Ensure it's an array
      const newAccounts = event.target.checked
        ? [...socialsAccounts, selectedAccount]
        : socialsAccounts.filter((acc) => acc !== selectedAccount);
      return { ...prevDetails, socials_accounts: newAccounts };
    });
  };

  const isChecked = (name, platform) => {
    return (dealDetails.socials_accounts || []).includes(`${name}@${platform}`);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} lg={7} xl={7} sx={{ mt: 0 }}>
          <Box sx={{ mt: 1, mb: 5, width: '100%', height: 295 }}>
            <FormLabel component="legend">Twitter and Threads</FormLabel>
            <DataGrid
              rows={twitterThreadsRows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]} // 3
              hideFooter
              disableColumnMenu
              disableColumnSelector
              disableColumnFilter
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={5} lg={5} xl={5} sx={{ mt: 0 }}>
          <Box sx={{ mt: 1, mb: 5, width: '100%', height: 295 }}>
            <FormLabel component="legend">Discord</FormLabel>
            <DataGrid
              rows={discordRows}
              columns={discordColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              hideFooter
              disableColumnMenu
              disableColumnSelector
              disableColumnFilter
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default DealEditorSocalAccountsSelector;
